import React, { FC } from 'react'
import { graphql, Link } from 'gatsby'

import {
  Frontmatter,
  TemplateProps
} from './template-types'

import {
  Breadcrumb,
  BreadcrumbItem,
  PageTitle,
  TitleBorder
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../components/layout'
import SEO from '../components/seo'
import TemplateContentRow from '../components/template-content-row'


const RDProgramsTemplate: FC<TemplateProps> = ({ data }: TemplateProps) => {
  const html: unknown = data.mdx.body
  const resource: Frontmatter = data.mdx.frontmatter

  return (
    <React.Fragment>
      <SEO
        title={ resource.title }
        canonicalUrl={ resource.canonicalUrl }
        description={ resource.seoDescription }
        lang={ resource.seoLang }
      />
      <Layout>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            Our Work
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/our-work/rd-programs/'>R&amp;D Programs</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            {resource.title}
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle>{resource.title}</PageTitle>
        <TitleBorder />
        <TemplateContentRow resource={ resource } html={ html } />
      </Layout>
    </React.Fragment>
  )
}

export default RDProgramsTemplate

export const query = graphql`
  query($nodeId: String!) {
    mdx(id: {eq: $nodeId}) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        additionalTags
        alt
        image {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        canonicalUrl
        seoDescription
        seoLang
        sidebarCards {
          cardText
          cardTitle
          contacts {
            infoat
            person
          }
          form {
            type
            sendTo
          }
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          imageAlt
          urls {
            buttonText
            external
            url
            disabled
          }
        }
        title
      }
    }
  }
`
